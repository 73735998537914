<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure
    as="nav"
    class="bg-gray-800"
    v-slot="{ open }"
  >
    <div class="px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </DisclosureButton>
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/home">
              <img
                class="block h-8 w-auto"
                src="../assets/LV_2b_blanc.png"
                alt="LV"
              >
            </router-link>
          </div>
        </div>
        <div class="hidden sm:flex flex-1 items-center justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/home">
              <img
                class="block h-8 w-auto"
                src="../assets/LV_2b_blanc.png"
                alt="LV"
              >
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.to"
                :aria-current="item.current ? 'page' : undefined"
                v-slot="{ isActive }"
              >
                <p
                  :class="[isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']"
                >
                  {{ $t(item.name) }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div
            v-for="locale in locales"
            :key="locale"
          >
            <button
              class="mr-2 mb-1"
              type="button"
            >
              <country-flag
                :country="locale"
                size="normal"
                @click="changeLocal(locale)"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link
          v-for="item in navigation"
          :key="item.name"
          :to="item.to"
          :aria-current="item.current ? 'page' : undefined"
          v-slot="{ isActive }"
        >
          <p :class="[isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']">
            {{ $t(item.name) }}
          </p>
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import CountryFlag from 'vue-country-flag-next'

const locales = ['fr', 'gb', 'pt', 'it', 'es']

export default {
    name: 'Navbar',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    CountryFlag,
  },
  setup() {
    return {
      locales,
    }
  },
  data() {
    return {
      navigation: [
        { name: 'map', to: '/home', current: false },
        { name: 'themes', to: '/themes', current: false },
        { name: 'aboutMe', to: '/about', current: false },
        { name: 'contact', to: '/contact', current: false },
      ],
    }
  },
  methods: {
    changeLocal(lang) {
      this.$i18n.locale = lang;
    },
  }
}
</script>