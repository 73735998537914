export default {
  "luis-quote": {
    "maxime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From all  these pictures taken during different journeys, I keep in memory the infinite beauty of the world and through the richness of the encounters the conviction of greatness of man."])}
  },
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Map"])},
  "backToMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to map"])},
  "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Themes"])},
  "topics": {
    "papillions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butterflies"])},
    "poignees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handles"])},
    "ciels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skies"])},
    "carnavals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carnival"])},
    "lunes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moons"])},
    "portes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors Windows"])},
    "religieux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religious"])}
  },
  "bio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biography"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese citizen, I arrived in France quite young. Hadn’t travelled much, mostly just studied… meals in front of tv watching the 8:00pm news, so not an environment conducive to communication… and then, great holidays with grandparents that allowed me everything that was otherwise forbidden in normal times, in a beautiful Portuguese region of hills and full of history, ranging from the sweet Lisbon to the monastery of Batalha, from the endless beaches of Peniche to the farm of Alcanede surrounded by tall eucalyptus trees, shivering at the slightest breeze."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memories, scents, freedom."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My first trip: Greece at 18. The first of a long series, mostly with a backpack, with a desire for limitless independence and the world as my playground."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photography came not only as a way to observe and pay homage to this playground,  to its large spaces as its small details, but also as a precious trustee of my memories. The view of a landscape, a face, a scene… and magic happens, the story comes back, instant recall, every detail alive."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The emotion of a journey is that feeling so often felt,  to be so insignificant in front of the immensity of it all, and yet be humbled when realizing how special I am, how unique it is to experience all that immensity by myself, just for me."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What makes a great trip? All countries have their interests, either landscapes, history or architecture, but what marks an excellent journey is always the people I meet, the human touch."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a photo, the one that appeals to you, the one that speaks to you, enlarge and take the time to look. It has not undergone extensive image editing software, at best, perhaps some cropping or brightness adjustments. "])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And if it makes you want to go, even if you never will, it will be the most rewarding gift for me."])}
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "contactMe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean Dupont"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi Luis, ..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been sent to me. I will answer you as soon as possible !"])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])}
}