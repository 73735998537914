export default {
  "luis-quote": {
    "maxime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De todas estas imágenes tomadas durante mis viajes, guardo en la memoria la infinita belleza del mundo y a través de la riqueza de los encuentros, la convicción de la grandeza del Hombre."])}
  },
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa del Mundo"])},
  "backToMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al Mapa"])},
  "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quién soy"])},
  "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas Específicos"])},
  "topics": {
    "papillions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mariposa"])},
    "poignees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manijas"])},
    "ciels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cielos"])},
    "carnavals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carnavales"])},
    "lunes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunas"])},
    "portes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puertas Ventanas"])},
    "religieux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religioso"])}
  },
  "bio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografía"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nacionalidad Portuguesa, llegué a Francia muy joven. Pocas salidas, solo estudios... las comidas en el telediario de las 8 que impedían toda comunicación... pero unas vacaciones maravillosas en casa de los abuelos que me permitían todo lo que estaba prohibido en tiempos normales, en una hermosa región de colinas y cargada de historia, que va desde la dulce Lisboa al monasterio de Batalha, de las playas sin fin de Peniche a la finca de Alcanede rodeada de eucaliptos estremeciéndose con el menor soplo de viento. "])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerdos, olores, despreocupación. "])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi primer viaje a Grecia fue a los 18. El primero de una larga serie, en su gran mayoría con una mochila, con un deseo de libertad sin límites y como campo de juego: el Mundo."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fotografía llegó no solo como el medio de observar este último, tanto en sus grandes espacios como en sus pequeños detalles, sino también como una valiosa ayuda para mi memoria. La vista de un paisaje, una cara, una escena y la magia sucede, la historia vuelve, desfila, vive. "])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La emoción del viaje es ese sentimiento que tan a menudo se siente al ser tan pequeño ante la inmensidad, allí, ante mí y su sentimiento contrario, sentirse muy grande, porque es para mí, solo para mí. "])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es un gran viaje? Todos los países tienen sus intereses, ya sea los paisajes, la historia o la arquitectura, pero lo que siempre marca la diferencia son las relaciones humanas, los encuentros."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en una foto, la que le atraiga, la que le hable, amplíe y tómese el tiempo de mirarla. No ha sido fuertemente procesada en un editor de imágenes, en el mejor de los casos un recorte y un ajuste de luminosidad. "])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le hace querer ir allí, aunque nunca lo haga, será un gran regalo para mí."])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])}
}