export default {
  "luis-quote": {
    "maxime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De todas as imagens tiradas durante diferentes viagens, guardo na memória a beleza infinita do mundo e através da riqueza dos encontros, a convicção da grandeza do homem."])}
  },
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa Mundial"])},
  "backToMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao Mapa"])},
  "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
  "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas Específicos"])},
  "topics": {
    "papillions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borboleta"])},
    "poignees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maçanetas"])},
    "ciels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Céus"])},
    "carnavals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carnavais"])},
    "lunes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luas"])},
    "portes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portas Janelas"])},
    "religieux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religioso"])}
  },
  "bio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografia"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nacionalidade portuguesa, cheguei a França muito jovem. Poucos passeios, apenas estudos... refeições em frente ao noticiário das 8 que impediram qualquer comunicação...mas as férias maravilhosas com avós que me permitiram tudo o que era proibido em tempos normais, numa magnífica região de colinas e cheia de história, que vai de a doce  Lisboa ao mosteiro da Batalha, das intermináveis praias de Peniche à quinta de Alcanede rodeada de eucaliptos a tremer ao menor sopro de vento."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memórias, cheiros, despreocupação."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha primeira viagem: à Grécia aos 18 anos. O primeiro de uma longa série, na sua maioria com uma mochila, com um desejo de liberdade sem limites e como terreno de jogo: o Mundo."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fotografia veio não só como um meio de o observar nos seus grandes espaços, bem como nos seus pequenos detalhes, mas também como uma preciosa ajuda para a minha memória. A visão de uma paisagem, de um rosto, de uma cena e a magia acontece, a história volta, desfila, vívida."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A emoção da viagem é esta sensação tantas vezes sentida de ser muito pequeno frente à imensidão, ali, diante de mim e do seu sentimento oposto, sensação de ser muito grande porque é para mim, só para mim."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que é uma grande viagem? Todos os países têm o seu interesse, seja pelas paisagens, pela história ou pela arquitectura mas o que faz a diferença são sempre as relações humanas, os encontros."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique numa fotografia, aquela que lhe apela, aquela que lhe fala, amplie-a e tome o tempo de a observar. Não foi  fortemente processada num editor de imagens, na melhor das hipóteses apenas cortada ou a luminosidade ajustada."])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se lhe dá vontade de lá ir, mesmo que nunca o faça, será uma grande prenda para mim."])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder"])}
}