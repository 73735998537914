export default {
  "luis-quote": {
    "maxime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di tutte le immagini scattate durante i miei viaggi, conservo nella memoria l'infinita bellezza del mondo e attraverso la ricchezza degli incontri, la convinzione della grandezza dell'Uomo."])}
  },
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa del Mondo"])},
  "backToMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Mappa"])},
  "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi sono"])},
  "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temi Specifici"])},
  "topics": {
    "papillions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farfalle"])},
    "poignees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglie"])},
    "ciels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cieli"])},
    "carnavals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carnevale"])},
    "lunes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lune"])},
    "portes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte Finestre"])},
    "religieux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religioso"])}
  },
  "bio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografia"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portoghese, sono arrivato in Francia da piccolo. Poche uscite, solo studio… cene davanti al TG delle 20 che impediva ogni genere di comunicazione… poi le vacanze meravigliose dai nonni che mi permettevano tutto ciò che era vietato nel resto dell’anno tutto ciò in una regione magnifica di colline, carica di storia che si stendeva dalla dolce Lisbona al monastero di Batalha, dalle spiagge senza fine della Peniche alla fattoria d’Alcanede circondata di eucalipti fruscianti al minino soffio di vento."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordi, odori, spensieratezza."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio primo viaggio a 18 anni in Grecia. Il primo di una lunga serie, nella grande maggioranza dei casi con uno zaino sulle spalle, con una voglia di Libertà senza limiti e come terreno di gioco: il mondo."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fotografia è diventata non soltanto strumento di osservazione di quest’ultimo, nei sui grandi spazi così come nei piccoli dettagli, ma anche come supporto prezioso alla memora. La vista di un paesaggio, un volto, una scena e la magia si attiva, la storia ritorna e si snoda vivente."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’emozione del viaggio è il sensazione che spesso ho provato nel sentirmi così piccolo di fronte all’Immensità là davanti e la sensazione opposta di sentirmi così grande perché tutto questo è per me, nient’altro che per me."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa rende un viaggio straordinario? Ogni paese ha la sua peculiarità, nei paesaggi, nella storia, l’architettura ma quello che fa la differenza sono sempre gli incontri, le relazioni umane."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliccate su una foto, quella che vi attrae, quella che vi dice qualcosa. Ingranditela e prendetevi il tempo di guardarla; non ha subito importante trattamento con uno  software de ritoco de imagini, al massimo una riquadratura migliore, un leggero ritocco alla luminosità. "])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se questa foto vi darà la voglia di andarci, anche se non lo farete mai, questa sarà per me una imensa joia."])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedere"])}
}