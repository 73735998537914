<template>
  <div>
    <div
      class="hello bg-blue-300"
      ref="chartdiv"
    />
    <gallery ref="gallery" />
    <div ref="images">
      <div
        v-if="samples.length"
        class="mt-2"
      >
        <div class="text-4xl font-maxime tracking-widest text-white text-center">
          {{ title }}
        </div>
        <button
          class="float-right fixed bottom-2 right-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          @click="goToTop()"
        >
          {{ $t('backToMap') }}
        </button>
      </div>
      <div class="grid lg:grid-cols-3 sm:grid-cols-2 place-items-center">
        <div
          class="m-2"
          v-for="(sample, index) in samples"
          :key="sample"
          @click="imgClick(index)"
        >
          <img
            class="object-contain h-96 w-96"
            :src="sample"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am5core from "@amcharts/amcharts5";
import * as am5maps from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import countriesData from '../assets/countries.json';
import Gallery from './Gallery.vue';


export default {
  components: { Gallery },
  name: 'Map',
  computed: {
      cities() {
          return countriesData.countries.map((country) => {
              return country.cities || null;
          });
      },
  },
  data() {
      return {
          images: [],
          samples: [],
          title: null,
          currentActiveBullet: null,
      }
  },
  methods: {
      imgClick(index) {
        this.$refs.gallery.$viewerApi({
          options: {
            toolbar: false,
            initialViewIndex: index,
            navbar: false,
            title: false
          },
          images: this.samples,
        });
      },
      goToTop() {
        this.$refs['chartdiv'].scrollIntoView({ behavior: "smooth" });
        this.samples = [];
        this.title = null;
      },
      goTo(ref) {
        this.$refs[ref].scrollIntoView({ behavior: "smooth" });
      }
  },
  mounted() {
    /**
     * Define SVG path for target icon
     */
    // var targetSVG = "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";
    let root = am5core.Root.new(this.$refs.chartdiv);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    let chart = root.container.children.push(
      am5maps.MapChart.new(root, {
        projection: am5maps.geoMercator(),
        geoJSON: am5geodata_worldLow
      })
    );

    // Not yet visited country series
    var notYetVisitedCountry = chart.series.push(am5maps.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow,
      fill: am5core.color(process.env.VUE_APP_MAP_COUNTRY_COLOR),
      strokeOpacity: 0.5,
      nonScalingStroke: true,
    }));

    notYetVisitedCountry.useGeodata = true;

    // Configure series
    notYetVisitedCountry.mapPolygons.template.setAll({
      tooltipText: "{name}",
    });

    // Visited country series
    var includedCountries = [];
    var excludedCountries = ['AQ'];
    countriesData.countries.forEach(country => {
        includedCountries.push(country.key);
        excludedCountries.push(country.key);
    });
    var visitedCountry = chart.series.push(am5maps.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow,
      fill: am5core.color(process.env.VUE_APP_MAP_COUNTRY_VISITED_COLOR),
      strokeOpacity: 0.5,
      nonScalingStroke: true,
      togglable: false,
      applyOnClones: true,
      include: includedCountries,
    }));
    visitedCountry.useGeodata = true;
    
     visitedCountry.mapPolygons.template.setAll({
      tooltipText: "{name}",
    });

    visitedCountry.mapPolygons.template.events.on("click", function(ev) {
        visitedCountry.zoomToDataItem(ev.target.dataItem);
    });

    // Selected State
    visitedCountry.mapPolygons.template.states.create("active", {
      fill: am5core.color(process.env.VUE_APP_MAP_COUNTRY_SELECTED_COLOR)
    });

    // Hover state
    visitedCountry.mapPolygons.template.states.create("hover", {
      fill: am5core.color(process.env.VUE_APP_MAP_COUNTRY_HOVER_COLOR),
    });

    // Create point series
    var pointSeries = chart.series.push(am5maps.MapPointSeries.new(root, {
      latitudeField: "latitude",
      longitudeField: "longitude",
    }));

    // Active state
    /*pointSeries.mapPoints.template.states.create("active", {
      fill: am5core.color("red"),
    });*/
    var that = this;
    pointSeries.bullets.push(function() {
      let circle = am5core.Circle.new(root, {
        radius: 5,
        fill: am5core.color(0x000000),
        tooltipText: "{title}",
        toggleKey: "active",
      });

      circle.states.create("active", {
        radius: 10,
        fill: am5core.color(0xff0000)
      });

      circle.states.create("hover", {
        fill: am5core.color(0xff0000)
      });

      circle.on("active", function(active, target) {
        if (that.currentActiveBullet) {
          that.currentActiveBullet.set("active", false);
        }
        if (active) {
          that.currentActiveBullet = target;
        }
        else {
          that.currentActiveBullet = undefined;
        }
      });

      circle.events.on("click", function(ev) {
        ev.target.active = true;
        let city = ev.target.dataItem.dataContext;
        const illustrations = require.context(
          `../../public/images/`,
          true,
          /^.*\.*$/
        )
        const images = illustrations.keys().filter(path => path.includes(`/${city.title}/`)).map((i) => {
          return i.replace('./', `images/`);
        });
        that.title = city.title;
        that.samples = images;
        that.goTo('images');
        // imageSeries.children.values.filter(child => { return child.isActive && child.cloneId !== ev.target.cloneId; }).forEach((child) => child.isActive = false);
      });

      return am5core.Bullet.new(root, {
        sprite: circle
      });
    }, this);
    pointSeries.data.setAll(this.cities);
  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>