<template>
  <div class="bg-black">
    <luis-quote class="mb-5" />
    <Map />
  </div>
</template>

<script>
import LuisQuote from '../components/LuisQuote.vue'
import Map from '../components/Map.vue'

export default {
  name: 'Home',
  components: {
    LuisQuote,
    Map,
  }
}
</script>
