<template>
  <div class="md:text-3xl sm:text-xl font-maxime tracking-widest text-white text-center">
    {{ $t('luis-quote.maxime') }}
  </div>
</template>

<script>
export default {
  name: 'LuisQuote',
}
</script>
