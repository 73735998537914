export default {
  "luis-quote": {
    "maxime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De toutes ces images prises lors de différents voyages, je garde en mémoire l'infinie beauté du monde et par la richesse des rencontres la conviction de la grandeur de l'homme."])}
  },
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte Mondiale"])},
  "backToMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la carte"])},
  "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
  "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thèmes spécifiques"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "topics": {
    "papillons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papillons"])},
    "poignees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignées"])},
    "ciels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciels"])},
    "carnavals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carnavals"])},
    "lunes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
    "portes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes Fenêtres"])},
    "religieux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religieux"])}
  },
  "bio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biographie"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nationalité Portugaise, je suis arrivé en France très jeune. Peu de sorties, que des études… les repas devant le JT de 20h qui empêche toute communication… et puis les vacances chez les grands-parents qui me permettaient tout ce qui était interdit en temps normal, le pied, dans une magnifique région de collines et chargés d’histoire, allant de la douce Lisbonne au monastère de Batalha, des plages sans fin de Péniche à la ferme d’Alcanede entourée d’eucalyptus frissonnants au moindre coup de vent."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenirs, odeurs, insouciance."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon premier voyage : en Grèce à 18ans. Le premier d’une longue série, en grande majorité avec un sac à dos, avec une envie de Liberté sans limites et comme terrain de jeu : le Monde."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La photographie est venue non seulement comme le moyen d’observer ce dernier dans ses grands espaces comme dans ses petits détails, mais aussi une aide précieuse à ma mémoire. La vue d’un paysage, un visage, une scène et la magie opère, l’histoire revient, défile, vivante."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’émotion du voyage c’est ce sentiment si souvent ressenti d’être tout petit devant l’immensité, là, devant moi et son sentiment contraire, se sentir très grand parce que c’est pour moi, rien que pour moi."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est quoi  un grand voyage ? Tous les pays ont leur intérêt, soit les paysages, soit l’histoire, soit l’architecture mais ce qui fait la différence c’est toujours les relations  humaines, les rencontres."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur une photo, celle qui vous interpelle, celle qui vous parle, agrandissez et prenez le temps de regarder. Elle n’a pas subit de traitement poussé, au mieux un recadrage et un réglage de luminosité."])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si elle vous donne envie d’y aller, même si vous ne le ferez jamais, ce sera un immense cadeau pour moi."])}
  },
  "contactMe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me contacter"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean Dupont"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message"])},
    "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut Luis, ..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message m'a été envoyé. Je vous répondrais au plus vite !"])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])}
}