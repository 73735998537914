import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import i18n from './i18n'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


createApp(App).use(i18n).use(router).use(VueViewer).mount('#app')
